// 言語リスト
export default {
  namespaced: true,
  state: [
    {
      lang: "日本語",
      value: "jp",
    },
    {
      lang: "English",
      value: "en",
    },
  ],
};
